import {API} from "aws-amplify";

const getRecentCalls = async ({queryKey}) => {
    // eslint-disable-next-line
    const [_key, {start, pageSize, from, to, lat, lng}] = queryKey;
    const fromDateEpoch = from ? from.getTime()/1000 : "";
    const toDateEpoch = to ? to.getTime()/1000 : "";
    return await API.get("dev-psap",
        `/calls?start=${start}&pageSize=${pageSize}&from=${fromDateEpoch}&to=${toDateEpoch}&lat=${lat}&lng=${lng}`,
        null);
};

const getCallDetails = async ({queryKey}) => {
    // eslint-disable-next-line
    const [_key, {callUuid}] = queryKey;
    return await API.get("dev-psap", `/call/${callUuid}`, null);
};

export {getCallDetails, getRecentCalls};