import parseGeoraster from "georaster";
import proj4 from "proj4-fully-loaded";
import L from "leaflet";

window.proj4 = proj4.default;
const cachedPlans = {};

const loadFloorPlan = async (url) => {
    if (cachedPlans[url]) return cachedPlans[url];

    const img = await fetch(url)
    const buffer = await img.arrayBuffer();
    const geoRaster = await parseGeoraster(buffer);
    cachedPlans[url] = geoRaster;
    return geoRaster;
}

const convertToLatLng = ({xmin, xmax, ymin, ymax}) => {
    const midY = (ymax + ymin) / 2;
    const midX = (xmin + xmax) / 2;
    const point = new L.Point(midX, midY);
    return L.Projection.SphericalMercator.unproject(point);
}

export {loadFloorPlan, convertToLatLng};
