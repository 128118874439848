import {extractIndoorMapLink} from "../util/util";

const CallHistoryRawItem = ({history}) => {
    const {created, payload} = history;
    const parsedDate = new Date(Date.parse(created));
    const hasResolvedLocations = !!(payload && payload.length && payload[0].rankedLocations.length);
    const {
        gpsReportedLatitude,
        gpsReportedLongitude,
        gpsLatitude,
        gpsLongitude,
        batteryReading
    } = payload && payload.length && payload[0] ? payload[0] : {};
    const drift = gpsReportedLongitude && gpsReportedLatitude
        ? calcCrow(gpsReportedLatitude, gpsReportedLongitude, gpsLatitude, gpsLongitude)
        : null;
    return <div className="min-w-96 w-full m-1 p-2 flex flex-col">
        <div className="flex flex-row">
            <span className="w-full ml-2 mb-1 font-medium text-slate-800">
                {parsedDate.toLocaleTimeString()}
            </span>
        </div>
        {gpsLatitude && gpsLongitude && <>
            <div className="ml-2 mt-3 mb-2 flex flex-row text-sm font-medium flex-wrap">
                {LocationIcon} {gpsLatitude + ", " + gpsLongitude}
            </div>
        </>
        }
        <div className="ml-2 mt-3 mb-2 flex flex-row text-sm font-medium flex-wrap">
            {DriftIcon} {drift ? drift + " m" : "--"}
        </div>
        {batteryReading && <div className="ml-2 mb-4 flex flex-row text-sm font-medium">
            {BatteryIcon} <span>{batteryReading + " %"}</span>
        </div>}
        <div className="divide-y-2 divide-slate-400">
            {hasResolvedLocations && payload[0].rankedLocations.map(location => {
                let hrl = location.humanReadableLocation;
                hrl = hrl.replace(/(\n)+/g, "\n");
                let {mapLink, strippedHrl} = extractIndoorMapLink(hrl);
                const wifiIcon = location.connected ? <ConnectedIcon/> : <DisConnectedIcon/>;
                return <div className="p-2 flex flex-col mb-2">
                    <div className="flex flex-row mb-1">
                        {ConfidenceIcon}
                        <span className="font-medium text-sm mr-3">{location.confidence}</span>
                        {wifiIcon}
                        <span className="font-medium text-sm mr-3">{location.tagUsed} (SSID: {location.ssid})</span>
                        {mapLink && <>
                            {IndoorMapIcon}
                            <a target="_blank" rel="noreferrer" className="underline text-sm font-medium"
                               href={decodeURIComponent(mapLink)}>Indoor Map</a>
                        </>
                        }
                    </div>
                    <span className="ml-2 whitespace-pre-line text-sm">{strippedHrl}</span>
                </div>
            })}
            {!hasResolvedLocations && <span className="font-medium text-sm pl-2">No Resolved Locations</span>}
        </div>
    </div>;
};

export default CallHistoryRawItem;

const IndoorMapIcon = <svg className="h-5 w-5 mr-1 text-blue-600" width="24" height="24" viewBox="0 0 24 24"
                           strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                           strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z"/>
    <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"/>
    <line x1="10" y1="14" x2="20" y2="4"/>
    <polyline points="15 4 20 4 20 9"/>
</svg>;
const ConfidenceIcon = <svg className="h-5 w-5 mr-1 text-blue-600" width="24" height="24" viewBox="0 0 24 24"
                            strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                            strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z"/>
    <rect x="3" y="12" width="6" height="8" rx="1"/>
    <rect x="9" y="8" width="6" height="12" rx="1"/>
    <rect x="15" y="4" width="6" height="16" rx="1"/>
    <line x1="4" y1="20" x2="18" y2="20"/>
</svg>;
const DisConnectedIcon = () => <svg className="h-5 w-5 mr-1 text-blue-700" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <line x1="1" y1="1" x2="23" y2="23"/>
    <path d="M16.72 11.06A10.94 10.94 0 0 1 19 12.55"/>
    <path d="M5 12.55a10.94 10.94 0 0 1 5.17-2.39"/>
    <path d="M10.71 5.05A16 16 0 0 1 22.58 9"/>
    <path d="M1.42 9a15.91 15.91 0 0 1 4.7-2.88"/>
    <path d="M8.53 16.11a6 6 0 0 1 6.95 0"/>
    <line x1="12" y1="20" x2="12.01" y2="20"/>
</svg>;
const ConnectedIcon = () => <svg className="h-6 w-6 pb-1 mr-1 text-blue-700" viewBox="0 0 24 24" fill="none"
                                 stroke="currentColor"
                                 strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M5 12.55a11 11 0 0 1 14.08 0"/>
    <path d="M1.42 9a16 16 0 0 1 21.16 0"/>
    <path d="M8.53 16.11a6 6 0 0 1 6.95 0"/>
    <line x1="12" y1="20" x2="12.01" y2="20"/>
</svg>;
const LocationIcon = <svg className="h-4 w-4 mr-2 text-blue-700" width="24" height="24" viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z"/>
    <path d="M21 3L14.5 21a.55 .55 0 0 1 -1 0L10 14L3 10.5a.55 .55 0 0 1 0 -1L21 3"/>
</svg>;
const BatteryIcon = <svg className="pb-1 h-7 w-7 mr-1 text-blue-700" width="24" height="24" viewBox="0 0 24 24"
                         strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                         strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z"/>
    <path
        d="M6 7h11a2 2 0 0 1 2 2v.5a.5 .5 0 0 0 .5 .5a.5 .5 0 0 1 .5 .5v3a.5 .5 0 0 1 -.5 .5a.5 .5 0 0 0 -.5 .5v.5a2 2 0 0 1 -2 2h-11a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2"/>
    <line x1="7" y1="10" x2="7" y2="14"/>
    <line x1="10" y1="10" x2="10" y2="14"/>
    <line x1="13" y1="10" x2="13" y2="14"/>
</svg>;
const DriftIcon = <svg width="24" height="24"
                       className="h-7 w-7 pb-1 mr-1 text-blue-700"
                       viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"
                       strokeWidth="3" stroke="#000000" fill="none">
    <path
        d="M17.94,54.81a.1.1,0,0,1-.14,0c-1-1.11-11.69-13.23-11.69-21.26,0-9.94,6.5-12.24,11.76-12.24,4.84,0,11.06,2.6,11.06,12.24C28.93,41.84,18.87,53.72,17.94,54.81Z"/>
    <circle cx="17.52" cy="31.38" r="4.75"/>
    <path
        d="M49.58,34.77a.11.11,0,0,1-.15,0c-.87-1-9.19-10.45-9.19-16.74,0-7.84,5.12-9.65,9.27-9.65,3.81,0,8.71,2,8.71,9.65C58.22,24.52,50.4,33.81,49.58,34.77Z"/>
    <circle cx="49.23" cy="17.32" r="3.75"/>
    <path d="M17.87,54.89a28.73,28.73,0,0,0,3.9.89"/>
    <path d="M24.68,56.07c2.79.12,5.85-.28,7.9-2.08,5.8-5.09,2.89-11.25,6.75-14.71a16.72,16.72,0,0,1,4.93-3"
          strokeDasharray="7.8 2.92"/>
    <path d="M45.63,35.8a23,23,0,0,1,3.88-.95"/>
</svg>

function calcCrow(lat1, lon1, lat2, lon2) {
    const R = 6371; // km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const lat1Rad = toRad(lat1);
    const lat2Rad = toRad(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Rad) * Math.cos(lat2Rad);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return Math.round(R * c * 1000);
}

// Converts numeric degrees to radians
function toRad(Value) {
    return Value * Math.PI / 180;
}