import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CallsFilter = ({fromDate = null, toDate = null, setFromDate, setToDate, clearDates, lat, lng, setLat, setLng}) => {
    const clearEnabled = fromDate != null || toDate != null;
    return <div className="pt-2 flex flex-col sm:flex-row">
        <DatePicker
            className="ml-0 sm:ml-2 mr-2 h-8 font-medium text-sm pl-2 ring-1 rounded"
            placeholderText="From"
            selected={fromDate}
            onChange={setFromDate}
            maxDate={toDate || new Date()}
        />
        <DatePicker
            className="mr-2 h-8 pl-2 font-medium text-sm mt-1 sm:mt-0 ring-1 rounded"
            placeholderText="To"
            selected={toDate}
            onChange={setToDate}
            minDate={fromDate}
            maxDate={new Date()}
        />
        <input
            className="mr-2 h-8 pl-2 font-medium text-sm mt-1 sm:mt-0 ring-1 rounded"
            onChange={e => setLat(e.target.value)}
            value={lat || ""}
            placeholder="Latitude"
        />
        <input
            className="mr-2 h-8 pl-2 font-medium text-sm mt-1 sm:mt-0 ring-1 rounded"
            onChange={e => setLng(e.target.value)}
            value={lng || ""}
            placeholder="Longitude"
        />
        <button
            onClick={clearDates}
            className={"mt-1 sm:mt-0 whitespace-nowrap relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-1 text-sm font-medium " + (clearEnabled ? " text-gray-700 hover:bg-gray-50" : " text-gray-400")}
            disabled={!clearEnabled}
        >
            {"X Clear"}
        </button>
    </div>;
};

export default CallsFilter;