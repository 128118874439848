import ReactJson from 'react-json-view';

const CallHistoryRawItem = ({history}) => {
    const {created, payload} = history;
    const parsedDate = new Date(Date.parse(created));
    return <div className="ml-2 m-1 p-2 flex flex-col min-w-250">
        <span className="mb-2 font-medium text-slate-800">
            {parsedDate.toLocaleTimeString()}
        </span>
        <span className="text-sm mb-1 font-medium text-slate-800">
            <ReactJson src={payload}
                       collapsed={true}
                       enableClipboard={false}
                       displayDataTypes={false}/>
        </span>
    </div>;
};

export default CallHistoryRawItem;