const RefreshButton = ({displaySpinner, getErrorMessage = () => "", refreshFn = () => {}}) => {
    return <div className="h-8 mt-2 ml-3 flex-col flex items-center justify-center cursor-pointer"
        onClick={refreshFn}>
        <div className={displaySpinner ? "animate-spin" : ""}>
            {RefreshIcon}
        </div>
        {getErrorMessage() && <div className="flex flex-row">
            <svg className="h-5 w-5 mt-1 mr-1 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
            </svg>
            <span className="text-lg text-red-400 break-words">{getErrorMessage()}</span>
        </div>
        }
    </div>
}
export default RefreshButton;

const RefreshIcon = <svg className="h-6 w-6 text-slate-500"
                         transform="scale (-1, 1)" transform-origin="center"
                         width="24" height="24"
                         viewBox="0 0 24 24" strokeWidth="2"
                         stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z"/>
    <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -5v5h5"/>
    <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 5v-5h-5"/>
</svg>;