import {useState} from "react";
import Tabs from "./Tabs";

function getAddress(dispatchableAddress) {
    const {borough = "", stateOrProvince = "", country = ""} = dispatchableAddress;
    let address = "";
    if (borough) {
        address += borough + ", ";
    }
    if (stateOrProvince) {
        address += stateOrProvince + ", ";
    }
    if (country) {
        address += country;
    }

    address = address || "No Resolved Location";
    return address;
}

const Call = ({phoneNumber, created, callUuid, eml}) => {
    const [detailOpen, setDetailOpen] = useState(false);
    const parsedDate = new Date(Date.parse(created));
    const toggleDetail = () => {
        setDetailOpen(!detailOpen);
        return false;
    }
    const phone = phoneNumber || "Not Available";

    const emlLocations = eml && eml.length && eml[0].payload && eml[0].payload.length
        ? eml[0].payload[0].rankedLocations
        : [];
    emlLocations.sort((l1, l2) => l1.confidence > l2.confidence ? -1 : 1);
    const topEmlLocation = emlLocations.length ? emlLocations[0] : {dispatchableAddress: {}};
    const {dispatchableAddress = {}} = topEmlLocation;
    return <div className="flex flex-col px-4 py-4 sm:px-6 bg-slate-200 rounded-md shadow-md">
        <div className="flex flex-row cursor-pointer" onClick={toggleDetail}>
            <div className="mr-4 my-auto">
                {!detailOpen && <svg className="h-6 w-6 text-blue-400" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M12 4v16m8-8H4"/>
                </svg>}
                {
                    detailOpen &&
                    <svg className="h-6 w-6 text-gray-600" width="24" height="24" viewBox="0 0 24 24"
                         strokeWidth="3" stroke="currentColor" fill="none" strokeLinecap="round"
                         strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z"/>
                        <line x1="5" y1="12" x2="19" y2="12"/>
                    </svg>
                }
            </div>
            <div className="flex flex-col w-full">
                <div className="flex flex-row pb-6">
                    {TimeIcon}
                    <span
                        className="ml-2 mb-1 font-medium text-slate-800">
                        {parsedDate.toDateString() + " " + parsedDate.toLocaleTimeString()}
                    </span>
                </div>
                <div className="flex flex-row mb-2">
                    {AddressIcon}
                    <span
                        className="ml-2 text-xl font-medium text-blue-800">
                        {getAddress(dispatchableAddress)}
                    </span>
                </div>
                <div className="flex flex-row">
                    {PhoneIcon}
                    <span className="ml-2 text-lg font-medium text-slate-800">{phone}</span>
                    <span className="ml-auto text-sm mr-1 pt-2 font-medium leading-5 text-slate-500">ID</span>
                    <span className="pt-2 font-medium leading-5 text-slate-400">{callUuid}</span>
                </div>
            </div>
        </div>
        <div className="flex bg-slate-300 shadow-inner mt-4 w-full px-1">
            <div className={"w-full" + (!detailOpen ? " hidden " : "")}>
                <Tabs callUuid={callUuid}
                      isOpen={detailOpen}
                />
            </div>
        </div>
    </div>;
};

export default Call;

const TimeIcon = <svg className="h-4 w-4 mt-1 ml-3 text-slate-600" width="24" height="24" viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z"/>
    <circle cx="12" cy="12" r="9"/>
    <polyline points="12 7 12 12 15 15"/>
</svg>;
const PhoneIcon = <svg className="ml-2 mt-1 h-5 w-5 text-slate-600" width="24" height="24" viewBox="0 0 24 24"
                       strokeWidth="2"
                       stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z"/>
    <path
        d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"/>
</svg>;
const AddressIcon = <svg className="ml-1 mt-1 h-6 w-6 text-blue-600" width="24" height="24" viewBox="0 0 24 24"
                         strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                         strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z"/>
    <path d="M8 9l5 5v7h-5v-4m0 4h-5v-7l5 -5m1 1v-6a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v17h-8"/>
    <line x1="13" y1="7" x2="13" y2="7.01"/>
    <line x1="17" y1="7" x2="17" y2="7.01"/>
    <line x1="17" y1="11" x2="17" y2="11.01"/>
    <line x1="17" y1="15" x2="17" y2="15.01"/>
</svg>;